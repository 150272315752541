window.showLoadingAlert = function (options) {
    const defaults = {
        message: 'Please wait while we process your request...',
        onShow: function () { },
        onHide: function () { },
        downloadUrl: null,
        isComplete: false
    };

    const settings = $.extend({}, defaults, options);

    let $loadingAlert = $('#loading-alert');
    if ($loadingAlert.length === 0) {
        $('body').append(`
            <div id="loading-alert" class="loading-alert" style="display: none;">
                <div class="loading-alert-content">
                    <div class="status-icon">
                        <div class="spinner"></div>
                        <div class="checkmark" style="display: none;">✓</div>
                    </div>
                    <div class="loading-text-container">
                        <span class="loading-message"></span>
                        <a href="#" class="download-link" style="display: none;">Download File</a>
                    </div>
                </div>
                <button class="close-button" style="display: none;">&times;</button>
            </div>
        `);

        $loadingAlert = $('#loading-alert');
    }

    $loadingAlert.data('onHide', settings.onHide);

    $loadingAlert.find('.close-button').off('click').on('click', function () {
        window.hideLoadingAlert();
    });

    $loadingAlert.find('.loading-message').text(settings.message);

    const $downloadLink = $loadingAlert.find('.download-link');
    $downloadLink.off('click');

    if (settings.isComplete) {
        $loadingAlert.find('.spinner').hide();
        $loadingAlert.find('.checkmark').show();
        $loadingAlert.find('.close-button').show();
    } else {
        $loadingAlert.find('.spinner').show();
        $loadingAlert.find('.checkmark').hide();
        $loadingAlert.find('.close-button').hide();
    }

    if (settings.downloadUrl) {
        $downloadLink
            .attr('href', settings.downloadUrl)
            .show()
            .on('click', function (e) {
                e.preventDefault();
                window.location.href = settings.downloadUrl;
            });
    } else {
        $downloadLink.hide();
    }

    $loadingAlert
        .removeClass('show')
        .show()
        .addClass('show')
        .on('animationend', function () {
            if (typeof settings.onShow === 'function') {
                settings.onShow();
            }
        });
};

window.hideLoadingAlert = function () {
    const $loadingAlert = $('#loading-alert');
    if ($loadingAlert.length) {
        $loadingAlert.removeClass('show');
        $loadingAlert.fadeOut(300, function () {
            const onHide = $loadingAlert.data('onHide');
            if (typeof onHide === 'function') {
                onHide();
            }
        });
    }
};
