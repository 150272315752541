$(document).on("click", "[role='variant-modal-trigger']", function (event) {
    event.preventDefault();
    event.stopPropagation();

    const $this = $(event.currentTarget);
    const $modal = $($this.attr("data-target"));
    const options = $this.attr("data-modal-type");
    const header = $this.data("header");
    const method = $this.data("method");

    if (options === "fixed") {
        $modal.attr("data-backdrop", "static");
        $modal.attr("data-keyboard", "false");
    }

    if (options === "cleared-on-close") {
        $modal.on("hide.bs.modal", function (event) {
            $modal.find(".modal-body").html("");
        });
    }

    $.ajax({
        url: $this.attr("href"),
        type: method === undefined ? "GET" : method,
        success: function (data) {
            showModal(data);
        },
    });

    function showModal(data) {
        $modal.modal("show");
        var modalTitle =
            "<h6 class='modal-title font-weight-bold'> This is a header </h6>";
        var modalClose =
            "<button type='button' class='close' data-dismiss='modal' aria-label='Close'><span aria-hidden='true' class='text-white'>×</span></button>";
        if (header !== undefined) {
            if ($modal.find(".modal-header").length === 0) {
                $modal
                    .find(".modal-content")
                    .prepend(
                        "<div class='modal-header bg-main text-white p-4'> " +
                        modalTitle +
                        modalClose +
                        "</div>"
                    );
            }
            $modal.find(".header-title").text(header);
            $modal.find(".modal-title").text(header);
        } else {
            $modal.find(".modal-header").remove();
        }
        $modal.find(".modal-body").html(data);
    }
});  