window.FlashAlert = (function () {
    return {
        show: function (message, type = 'success', duration = 5000) {
            const alertId = 'custom-flash-alert';
            let $flashAlert = $(`#${alertId}`);

            if ($flashAlert.length > 0) {
                $flashAlert.remove();
            }

            $('body').append(`
                <div id="${alertId}" class="custom-alert alert-dismissible alert-fixed d-flex justify-content-between alert-${type}" role="alert">
                    <i class="alert-icon ${type === 'success' ? 'fas fa-check-circle' : 'fas fa-exclamation-circle'}"></i>
                    <div class="alert-message">${message}</div>
                    <button type="button" class="btn-close" aria-label="Close">×</button>
                </div>
            `);

            if (!$('#flash-alert-styles').length) {
                $('head').append(`
                    <style id="flash-alert-styles">
                        .custom-alert {
                            position: fixed;
                            top: 20px;
                            right: 20px;
                            max-width: 500px;
                            z-index: 9999;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 1rem;
                            border-radius: 4px;
                            box-shadow: 0 2px 5px rgba(0,0,0,0.2);
                            animation: slideIn 0.3s ease-out;
                        }
                        .custom-alert.alert-success {
                            background-color: #d4edda;
                            border-color: #c3e6cb;
                            color: #155724;
                        }
                        .custom-alert.alert-error {
                            background-color: #f8d7da;
                            border-color: #f5c6cb;
                            color: #721c24;
                        }
                        .alert-icon {
                            margin-right: 12px;
                            font-size: 1.25rem;
                        }
                        .alert-message {
                            flex-grow: 1;
                            margin: 0 12px;
                        }
                        .btn-close {
                            padding: 0.5rem;
                            background: transparent;
                            border: none;
                            font-size: 1.25rem;
                            cursor: pointer;
                            opacity: 0.5;
                        }
                        .btn-close:hover {
                            opacity: 1;
                        }
                        @keyframes slideIn {
                            from {
                                transform: translateX(100%);
                                opacity: 0;
                            }
                            to {
                                transform: translateX(0);
                                opacity: 1;
                            }
                        }
                        @keyframes fadeOut {
                            from { opacity: 1; }
                            to { opacity: 0; }
                        }
                    </style>
                `);
            }

            $flashAlert = $(`#${alertId}`);

            $flashAlert.find('.btn-close').on('click', function () {
                $flashAlert.css('animation', 'fadeOut 0.3s ease-out');
                setTimeout(() => $flashAlert.remove(), 300);
            });

            if (duration) {
                setTimeout(() => {
                    if ($flashAlert.length) {
                        $flashAlert.css('animation', 'fadeOut 0.3s ease-out');
                        setTimeout(() => $flashAlert.remove(), 300);
                    }
                }, duration);
            }
        }
    };
})();
