window.showConfirmationModal = function(options) {
    const defaults = {
        title: 'Confirm Action',
        message: 'Are you sure you want to perform this action?',
        confirmText: 'Yes',
        cancelText: 'Cancel',
        onConfirm: function() {},
        onCancel: function() {}
    };

    const settings = $.extend({}, defaults, options);

    let $confirmModal = $('#confirmation-modal');
    if ($confirmModal.length === 0) {
        $('body').append(`
        <div class="modal fade" id="confirmation-modal" tabindex="-1" role="dialog" aria-labelledby="confirmation-modal-title" aria-modal="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="confirmation-modal-title">${settings.title}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        ${settings.message}
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary confirmation-cancel" data-dismiss="modal">${settings.cancelText}</button>
                        <button type="button" class="btn btn-primary confirmation-confirm">${settings.confirmText}</button>
                    </div>
                </div>
            </div>
        </div>
      `);
        $confirmModal = $('#confirmation-modal');
    }

    // Update modal content
    $confirmModal.find('.modal-title').text(settings.title);
    $confirmModal.find('.modal-body').text(settings.message);

    // Remove previous event handlers to prevent multiple bindings
    $confirmModal.find('.confirmation-confirm').off('click').on('click', function() {
        $confirmModal.modal('hide');
        if (typeof settings.onConfirm === 'function') {
            settings.onConfirm();
        }
    });

    $confirmModal.find('.confirmation-cancel').off('click').on('click', function() {
        $confirmModal.modal('hide');
        if (typeof settings.onCancel === 'function') {
            settings.onCancel();
        }
    });

    $confirmModal.modal('show');
};
